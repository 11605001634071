<template>
    <v-dialog 
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div class="base-popup" v-if="translates && translates[langUrl]">
            <div v-if="info">
                <div class="stripe-payment-info__trip">{{ info.name }}</div>
                <div class="stripe-payment-info__dates">{{ dateFormated(info.start) }} - {{ dateFormated(info.finish) }}</div>
                <div class="stripe-payment-info__places">{{ translates[langUrl].sideBlock_travelers[lang] }}: {{ info.places }}</div>
                <div class="stripe-payment-info__amount">{{ translates[langUrl].sideBlock_prepay_comment[lang] }}: <span v-html="priceFormated(info.amount, info.currency)"></span></div>
            </div>
            <StripeElementPayment
                v-if="clientSecret"
                ref="stripeElementPaymentRef"
                :pk="stripePublishableKey"
                :test-mode="testMode"
                :elements-options="elementsOptions"
                :confirm-params="confirmParams"
                /> 

            <BaseButton
                class="button-fill"
                style="width: 100%"
                @click="pay()">{{ translates[langUrl].sideBlock_button_pay[lang] }}</BaseButton>
        </div>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from '@/plugins/moment'
    import BaseButton from './BaseButton.vue'
    import { StripeElementPayment } from '@vue-stripe/vue-stripe';
    import { priceFormated } from '@/helpers';

    export default {
        name: 'StripePaymentPopup',
        components: {
            BaseButton,
            StripeElementPayment
        },
        props: {
            clientSecret: {
                type: String,
                default: ''
            },
            info: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            priceFormated,
            langUrl: '/trip/code/booking/id',
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('auth', ['user']),
            testMode() {
                return process.env.NODE_ENV === 'development';
            },
            elementsOptions() {
                return {
                    clientSecret: this.clientSecret,
                    appearance: {
                        theme: 'stripe',
                        variables: {
                            colorPrimary: '#DA0A63',
                            colorBackground: '#ffffff',
                            colorText: '#273155',
                        }
                    },
                    locale: this.lang
                }
            },
            confirmParams() {
                return {
                    return_url: process.env.VUE_APP_EN_URL + '/user/bookings',
                    payment_method_data: {
                        billing_details: {
                            name: this.user.firstname + ' ' + this.user.lastname,
                            email: this.user.email,
                            phone: this.user.phone,
                        }
                    }
                }
            },
            stripePublishableKey() {
                return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
            },
        },
        mounted() {
        },
        methods: {
            dateFormated(item) {
                return moment(item).format('L') + ' (' + moment(item).format('dddd') + ')';
            },
            pay() {
                this.$refs.stripeElementPaymentRef.submit();
            }
        }
    }
</script>

<style lang="scss">
.base-popup {
    width: 100%;
    padding: 15px 20px 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;

    #stripe-payment-element-mount-point {
        margin-top: 12px;
        margin-bottom: 20px;

        .Label {
            font-weight: 600;
            font-size: 16px !important;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #273155;
            padding: 12px 0;
        }
    }
    #stripe-payment-element-errors {
        margin-bottom: 20px;
        color: #df1b41;
        font-weight: 400;
        font-size: 0.93rem;
    }
}
.stripe-payment-info {
    &__trip {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #000000;
    }
    &__dates {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #3A4256;
        padding: 12px 0;
        border-bottom: 1px solid #EBEBEB;
    }
    &__places {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #273155;
        padding: 12px 0;
        border-bottom: 1px solid #EBEBEB;
    }
    &__amount {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #DA0A63;
        padding: 12px 0;
        border-bottom: 1px solid #EBEBEB;
    }
}
</style>